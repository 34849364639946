.solution-main-div {
  /* background-image: url("D:/projects/trinai/public/Images/Sliders/solutions.jpg"); */
  background-size: cover;
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* border: 1px solid red; */
  margin-top: 95px;
}
#Solutionheading {
  color: rgb(0, 0, 0);
  font-size: 70px;
  font-weight: bolder;
  margin-left: 25px;
}
.sol-main-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: justify;
  gap: 3rem;
}
.sol-first-division {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin-top: 20px;
}
#sol-heading {
  color: #0560f5;
  margin-top: 30px;
  margin-bottom: 20px;
  /* border: 1px solid red; */
}
.solu-img-mat-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.sol-img-div {
  width: 550px;
  height: 400px;
}
.sol-matter-span-con {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: 550px;
  gap: 1rem;
  height: 400px;
  overflow-y: scroll;
  position: relative;
  /* border: 1px solid black; */
}
.sol-background-div1 {
  width: 450px;
  height: 350px;
  position: absolute;
  top: 20%;
  left: -4%;
  z-index: -999;
}
.sol-background-div {
  width: 500px;
  height: 350px;
  position: absolute;
  top: 15%;
  left: -4%;
  z-index: -999;
}

.order-class {
  order: 4;
}

.un-order-class {
  order: 10;
}
.sol-background-div2 {
  width: 500px;
  height: 350px;
  position: absolute;
  top: 20%;
  right: -4%;
  z-index: -999;
}
.button-position {
  position: absolute;
  top: 98%;
  left: 0%;
}
.button-position-right {
  position: absolute;
  top: 100%;
  left: 55%;
}
@media (max-width: 768px) {
  .solution-main-div {
    width: 100%;
    height: 50vh;
  }
  .solu-img-mat-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 20px;
    gap: 3rem;
  }
  .sol-img-div {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .sol-matter-span-con {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    width: 100%;
    gap: 1rem;
    /* height: 400px; */
    overflow-y: scroll;

    /* border: 1px solid black; */
  }
  .sol-background-div2 {
    width: 450px;
    height: 350px;
    position: absolute;
    top: 60%;
    left: 5%;
    z-index: -999;
  }
  .sol-background-div1 {
    width: 450px;
    height: 350px;
    position: absolute;
    top: 5%;
    left: -5%;
    z-index: -999;
  }
}
