.middle-main-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  gap: 3rem;
  /* border: 1px solid red; */
}

.middle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  position: relative;
  margin-top: 20px;
  /* border: 1px solid black; */
}

.middle-img-container {
  /* width: 500px;
  height: 350px; */
  /* border: 1px solid red;  */
}
.middle-img-matter {
  width: 500px;
  height: 300px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 12px;
}
.middle-img-matter1 {
  width: 500px;
  height: 300px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 12px;
}
.btn {
  width: 150px;
  height: 35px;
  font-size: 22px;
}
.middle-color-con {
  width: 250px;
  height: 400px;
  position: absolute;
  top: -5%;
  left: 8%;
  z-index: -1;
  border-radius: 7px;
  background-color: #1285e494;
}
.middle-color-con1 {
  width: 250px;
  height: 400px;
  position: absolute;
  top: -5%;
  left: 75%;
  z-index: -1;
  border-radius: 7px;
  background-color: #1285e494;
}
@media (max-width: 768px) {
  .middle-main-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    /* border: 1px solid red; */
  }
  .middle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 10px; */
    /* border: 1px solid red; */
  }
  .middle-color-con {
    display: block;
  }
  .middle-img-container {
    width: 250px;
    /* height: 350px; */
    /* border: 1px solid red; */
  }
  .middle-img-matter {
    width: 100%;
    /* height: 100%; */
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .middle-img-matter1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* border: 1px solid red; */
  }

  .middle-color-con {
    display: none;
  }
  .middle-color-con1 {
    display: none;
  }
}
