.news-head-container {
  width: 100%;
  padding: 0px;
  margin-top: 95px;
  /* border: 2px solid red; */
}

.news-img-div {
  /* background-image: url("D:/projects/trinai/public/Images/Sliders/news feed.jpg");
  background-size: cover; */
  width: 100%;
  height: 70vh;
  position: relative;
}
#h2heading {
  position: absolute;
  top: 75%;
  left: 2%;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
}
.news-middle-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  padding: 20px;
}
.news-data-img {
  width: 100%;
  height: 200px;
  position: relative;
  top: -70px;
  border-radius: 5px 30px;
  border: 1px solid rgba(95, 91, 91, 0.247);
}
.news-loop-method {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 3rem;
  /* border: 2px solid red; */
}
.news-main-dta {
  width: 350px;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 4rem; */
  padding: 30px;
  border-radius: 7px;
  margin-top: 40px;
  margin-bottom: 45px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}
#h5heading {
  color: #1703fc;
}
.news-matter-div {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -50px;
  height: 300px;
  /* overflow: hidden; */
  /* border: 1px solid red; */
}
.btn1 {
  background-color: transparent;
  border: none;
  width: 150px;
  height: 40px;
  color: brown;
  position: absolute;
  top: 90%;
  left: 0%;
}
.btn1:hover {
  color: orange;
  background-color: transparent;
}

@media (max-width: 768px) {
  .news-img-div {
    width: 100%;
    height: 50vh;
  }
  .news-loop-method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 3rem;
    padding: 5px;
  }
  .news-data-img {
    width: 100%;
    height: 150px;
    position: relative;
    top: -70px;
  }
  .news-main-dta {
    width: 100%;
    /* height: 350px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: 4rem; */
    padding: 30px;
    border-radius: 7px;
    margin-top: 40px;
    margin-bottom: 45px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgba(128, 128, 128, 0.39);
    border-radius: 5px 30px;
    position: relative;
  }
}
