.Carousel--main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}
.carousel-container {
  width: 100%;
  height: 90vh;
}

@media (max-width: 768px) {
  .carousel-container {
    height: 50vh;
  }
}
