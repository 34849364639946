.header-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 15vh;
  padding: 10px 10px;
  position: fixed;
  top: 0%;
  z-index: 999;
  background-color: #fff;
  /* border: 1px solid red; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: 0 8px 16px rgba(82, 220, 255, 0.1);
}
.header-img-container {
  width: 10%;
}
.logo-img {
  width: 100%;
}
.header-sublist-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid black; */
}

.header--listitems-con > span:hover {
  color: #252525;

  /* border-bottom: 2px solid red; */
}
.header--listitems-con {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header--listitems-con > span {
  color: #232323;
}
.header-search-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 2px;
  /* margin-left: 12px; */
  /* padding: 5px 10px; */
}
.header-search-item {
  outline: none;
  border: none;
  height: 100%;
}
/* hvyjgyug */

.header-dropdown {
  position: relative;
  color: #232323;
  cursor: pointer;
  /* color: #0560f5; */
  /* border: 1px solid red; */
}

.header-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(82, 220, 255, 0.1);
  z-index: 1;
  width: 250px;
  border-radius: 7px;
  margin-top: 10px;

  /* border: 1px solid red; */
}
.header-dropdown-content :hover > span {
  background-color: rgb(0, 172, 252);
  color: white;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-content span {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}
.header-hamb-icon {
  display: none;
}
.prodtxcvghj {
  position: relative;
}
.header-hover-display-list {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 10px;
}
#header-color {
  color: #232323;
}
#header-color:hover {
  color: #fff !important;
  background-color: rgb(0, 172, 252);
  border-radius: 2px;
  /* width: 80px; */
  /* border: 1px solid red; */
  /* padding: 2px; */
}
.active {
  color: #fff !important;
  background-color: rgb(0, 172, 252);
  border-radius: 2px;
}
/* .header-hover-display-list {
  display: none;
} */
.header-hamb-content {
  display: none;
}

@media (max-width: 768px) {
  .header-main-container {
    position: relative;
  }
  .header--listitems-con {
    display: none;
  }
  .header-search-main {
    display: none;
  }
  .header-hamb-icon {
    display: flex;
    flex-direction: column;
    text-align: center;
    /* margin-left: 20px; */
    z-index: 999;
  }
  .header-hamb-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* height: auto; */
    z-index: 999;
    /* margin-top: 100px; */
    background-color: #ffffff;
    border-radius: 5px;
    width: 200px;
    /* gap: 1rem; */
    /* height: 300px; */
    position: absolute;
    top: 100%;
    left: 47%;
    cursor: pointer;
    /* color: #fff; */
    /* border: 1px solid red; */
    /* background-color: rgb(0, 172, 252); */
  }

  #products-hover {
    position: relative;
  }
  /*   */
  .header-hover-display-list > span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 0.1px solid grey; */
    border: 1px solid red;
    line-height: 12px;
    /* background-color: rgb(0, 172, 252); */
  }
  /* .header-hover-display-list:hover {
    background-color: #08ceff;
  } */
  .header-img-container {
    width: 25%;
  }
}
