.certificate__card {
  width: 100%;
  padding: 20px;
  /* border: 2px solid red; */
  margin-top: 70px;
  position: relative;
}
.pro-main-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  /* padding: 10px 20px; */
  /* border: 2px solid red; */
}
.pro-details-list {
  width: 50%;
  line-height: 35px;
  /* border: 1px solid red; */
}
.pro-details-image {
  width: 50%;
  height: 500px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  /* background-color: rgb(221, 218, 218); */
}
.pro-maindiv-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pro-main-sliderdiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}
.pro-spe-dow-class {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.pro-pre-list__items {
  display: flex;
  flex-direction: row;
}
.pro-pre-spe--download {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid red; */
  margin-top: 20px;
}

@media (max-width: 768px) {
  .pro-main-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
  .pro-pre-list__items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
  }
  .certificate__card {
    margin-top: 0px;
  }
  .pro-details-list {
    width: 100%;
  }
  .pro-details-image {
    width: 100%;
  }
  .pro-maindiv-container {
    font-size: 8px !important;
  }
}

/* previwe modal styles */

.previewModal-main-card {
  position: absolute;
  top: 5%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  z-index: 9999;
  width: 100%;
  /* border: 2px solid red; */
}

.previewModal-main-card > :nth-child(1) {
  width: 80%;
  /* border: 2px solid red; */
  border-radius: 7px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
}

.cros_preview-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-preview-card {
  width: 100%;
}
