.main-aboveus {
  width: 100%;
  padding: 5px;
  margin-top: 90px;
}
.aboutus-image-container {
  width: 100%;
  height: 70vh;
  /* border: 1px solid red; */

  position: relative;
}
#Aboutus {
  position: absolute;
  top: 68%;
  left: 2%;
  color: rgb(255, 255, 255);
  font-size: 70px;
  font-weight: bold;
  /* margin-left: 25px; */
}
.aboutus-midd-img {
  width: 100%;
  height: 70vh;
  /* border: 1px solid red; */
}
.about-side-midd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-div-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* border: 1px solid black; */
  width: 90%;
  gap: 2rem;
}
.about-first-0para {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#headingcolor {
  color: #0560f5;
}
.span {
  font-weight: 500;
}
@media (max-width: 768px) {
  .aboutus-image-container {
    width: 100%;
    height: 30vh;
  }
  .aboutus-midd-img {
    width: 100%;
    height: 30vh;
  }
}
