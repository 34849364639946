.bank-img-container {
  width: 100%;
  height: 25vh;
  /* border: 1px solid red; */
  margin-top: 95px;
}
.bank-heading {
  position: absolute;
  top: -12%;
  left: 35%;
  color: #fff;
}
/* #heading-background {
  background-image: url("D:/projects/trinai/public/Images/title.jpg");
  padding: 0px 120px 0px 15px;
  width: 260px;
  font-size: 24px;
  line-height: 32px;
  height: 32px;
  color: #fff;
  display: inline-block;
  background-size: cover;
} */

.bank-support-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  position: relative;
}
.bank-hero-container {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.bank-side-images-main {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}
.bank-side-images {
  width: 40%;
  height: 50%;
}
.bank-side-images-slides {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  width: 55%;
}
.bank-challenges-Solution-list {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.bank-solutions-image-div {
  width: 90%;
  height: 100vh;
  margin-top: 40px;
}
