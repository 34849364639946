.Contact-us-main-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-top: 85px;
  padding: 20px;
  gap: 1rem;
}
.query-form {
  width: 100%;
  border: 1px solid rgb(148, 141, 141);
  padding: 40px;
}
.query-form-sub-list {
  display: flex;
  flex-direction: column;
}
.form-group {
  display: flex;
  flex-direction: column;
  /* border: 1px solid orange; */
}
.form-label {
  display: flex;
  flex-direction: column;
}
.query-side-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.select-label-container {
  display: flex;
  flex-direction: column;
  /* height: 100px; */
  width: 50%;
  /* border: 1px solid red; */
}
#select-option {
  height: 40px;
  /* border: 1px solid rgb(255, 17, 17); */
}
.Contact-us-maps-location {
  width: 100%;
}
/* .form-button {
  width: 200px;
} */

@media (max-width: 768px) {
  .Contact-us-main-container {
    flex-direction: column;
  }
  .Contact-us-maps-location {
    margin: 20px;
  }
}
