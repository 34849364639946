.trend__product__main__card {
  width: 100%;
  padding: 20px;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trendprod-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3rem;
  padding: 30px;
  overflow-x: hidden;
}

.trend-main-imgcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 1rem;
  width: 350px;
  height: 300px;
  border-radius: 20px 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid rgba(172, 166, 166, 0.349);
}

.trend-main-imgcon:hover .trend-img-conta {
  /* transform: rotate(5deg); */
  transform: scale(1.1);
  /* zoom: 0.8; */
  transition: all 0.8s ease-in-out;
}

.trend-img-conta {
  width: 250px;
  height: 200px;
  padding: 10px;
}
@media (max-width: 768px) {
  .trend__product__main__card {
    padding: 10px;
  }
  .trendprod-container {
    gap: 1rem;
    padding: 20px;
    overflow-x: scroll;
  }
}
