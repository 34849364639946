.footer-full {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  line-height: 50px;
  width: 100%;
  height: 70%;
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.822);
  background-color: rgba(0, 0, 0, 0.836);

  padding: 10px;
  position: relative;
  /* border: 1px solid red; */
}

.footer-img-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 100px;
}

.footer-total-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  padding: 20px;
  margin-top: 20px;
}

.footer-product-con {
  display: flex;
  flex-direction: row;
}

.footer-span-product {
  display: flex;
  flex-direction: column;
}
.footer-span-con > span:hover {
  color: rgb(30, 173, 209);
}
.footer-span-product1 {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.footer-span-product > span:hover {
  color: rgb(30, 173, 209);
  transition: all 0.5s ease-in-out;
}
.footer-span-product1 > span:hover {
  color: rgb(30, 173, 209);
  transition: all 0.5s ease-in-out;
}
.footer-side-div-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer-information-con {
  width: 350px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}
.footer-span-con {
  display: flex;
  flex-direction: column;
}
.footer-contactus {
  display: flex;
  flex-direction: column;
}
.footer-contact-info {
  width: 350px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}
.footer-contact-info > span:hover {
  color: rgb(30, 173, 209);
  transition: all 0.5s ease-in-out;
}
.footer-span-contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* h4 {
  color: #fff;
} */
.footer-contat-End--container {
  display: flex;
  flex-direction: column;
}
.footer-contat-End--container > span:hover {
  color: rgb(30, 173, 209);
  transition: all 0.5s ease-in-out;
}
h2:hover {
  color: #fff;
  transition: all 0.5s ease-in-out;
}
#icon-style {
  transition: all 0.5s ease-in-out;
  /* color: red; */
}
.copy-right-container {
  color: rgb(177, 171, 171);
  /* font-weight: 200; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  font-weight: 100;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.836);
  padding-bottom: 12px;
}
@media (max-width: 768px) {
  .footer-full {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
  }
  .footer-total-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid white; */
  }
  .footer-side-div-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    /* border: 1px solid white; */
  }
  h2 {
    color: rgb(77, 174, 253);
  }
  .footer-total-container,
  .footer-side-div-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}
