.n-w-fiestdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
.n-w-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 150px;
  border-radius: 7px;
  text-align: center;
  background-color: #ffffff;
  margin-top: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.n-w-main-container:hover {
  transform: rotate(-5deg);
}
.n-w-main-container:hover > img {
  transform: scale(0.9);
}
/* types of px */
.n-w-types-con {
  width: 60%;
  height: 60px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 20px;
}

#gap > span:hover {
  color: #000;
}
#gap > span {
  background-color: #0dcaf0;
  border-radius: 7px;
  width: 300px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
#gap {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 1rem;
  margin-left: 12px;
}

.show_second_tab {
  display: block;
  gap: 2rem;
}

.hiden_second_tab {
  display: none;
}

.down_cams {
  width: 100%;
  /* border: 2px solid red; */
  /* height: 60%; */
  height: 500px;
}

.one_point_three_card {
  width: 100%;
  height: 100vh;
  /* border: 2px solid yellow; */
}
.one_point_three_card_1 {
  border: 2px solid green;
}
.one_point_three_card_2 {
  border: 2px solid blue;
}
.one_point_three_card_3 {
  border: 2px solid orange;
}
/* camera details css */
.nwcameras-main-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  /* height: 350px; */
  padding: 10px;
  margin: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* border: 2px solid red; */
  /* overflow: hidden; */
  position: relative;
}
.n-w-camera-details {
  /* border: 1px solid green; */
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.n-w-img-cam {
  width: 280px;
  height: 150px;
  /* border: 1px solid black; */
}
.n-w-matter-cam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: scroll;
  height: 150px;
  /* border: 1px solid red; */
}
.btn {
  height: 40px;
  margin-top: 15px;
}
