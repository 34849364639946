.mointor-main-img {
  background-image: url("D:/projects/trinai/public/Images/banner 1.jpg");
  background-size: cover;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}
.monitor-back-page {
  width: 450px;
  gap: 2rem;
  height: 80%;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  margin-left: 20px;
  border-radius: 7px;
  font-size: 22px;
}
.monitor-back-page > h1 {
  font-size: 28px;
}
.btn {
  color: white;
  width: 150px;
  height: 55px;
}
@media (max-width: 768px) {
  .mointor-main-img {
    /* width: 100%; */
    /* height: 100vh; */
    background-image: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .monitor-back-page {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
