.main-news-page {
  width: 100%;
  padding: 10px;
}
.np-img-container {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  position: relative;
  /* border: 2px solid red; */
}
#pgheading {
  position: absolute;
  top: 65%;
  left: 2%;
  font-size: 60px;
  font-weight: 600;
}
.pg-heaader-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.pg-hero-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
}
.np-fedd-data {
  width: 500px;
  gap: 2rem;
  /* border: 1px solid red; */
}
.np-img-div {
  width: 550px;
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.np-img-div > img {
  height: 100%;
}
#nph4heading {
  color: #1703fc;
}
.np-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  width: 90%;
}
@media (max-width: 768px) {
  .np-img-container {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .pg-hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
  }
  .np-fedd-data {
    width: 100%;
    gap: 2rem;
    /* border: 1px solid red; */
  }
  .np-img-div {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 20px;
    height: 200px;
  }
}
